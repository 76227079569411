<template>
  <Page id="cloudeng" anchor="cloudeng">
    <ServicesCard
      anchor="cloudeng-anchor"
      title="DX and Cloud Engineering"
      img="lines_of_code.webp"
      alt="Lines of Code on Laptop"
    >
      <p>
        Our multiskilled engineers develop cloud offerings to meet our customers
        engineering requirements.Bringing the latest cloud offerings to bring
        value to our customers computing needs. We systematically commercialise,
        standardise, and develop governance of cloud computing applications by
        leveraging the leading methods and tools.
      </p>
    </ServicesCard>
    <ServicesSplit
      id="cloudeng-anchor"
      img="lines_of_code.webp"
      problem="Digital Transformation can be a daunting prospect given the multiple
          cloud options available. Decisions about flexibility versus
          portability, hybrid or not, native or bespoke can be a headache for
          CTOs and CEOs."
      solution="We provide clarity to help understand the value digital transformation
          can bring to your business. Our goal is to understand your goal and
          demystify the complexity. We provide a method to simplify this goal
          into consumable stages that are efficient, safe and trusted. We
          explain, design and deliver the best outcome for your digital journey.
          We pride ourselves in how your data assets can be leveraged using
          cloud technologies and how you can bring your customers closer to you
          through the leveraging of high performance platforms and applications,
          automation and analytics."
    />
  </Page>
</template>

<script>
import ServicesSplit from "@/components/pages/services/ServicesSplit.vue";
import ServicesCard from "@/components/pages/services/ServicesCard.vue";
import Page from "@/components/core/Page.vue";

export default {
  components: {
    ServicesSplit,
    ServicesCard,
    Page,
  },
};
</script>

<style scoped>
</style>